import React, { useContext, useEffect, useState } from "react"

import { ChevronRight, Star } from "react-feather"
import Rating from "react-rating"
import { graphql } from "gatsby"
import * as dayjs from "dayjs"
import "dayjs/locale/de"
import axios from "axios"
import { sendConversion } from "../../components/GTM"
import Alert from "react-bootstrap/Alert"
import smoothScroll from "../../components/smoothScroll"
import LayoutContext from "../../components/LayoutContext"
import HelmetHead from "../../components/HelmetHead"
import * as relativeTime from "dayjs/plugin/relativeTime"

export const query = graphql`
  query {
    allStrapiRating(sort: { order: DESC, fields: date }) {
      edges {
        node {
          id
          name
          rating
          comment
          date
        }
      }
    }
    allGmbReview {
      edges {
        node {
          comment
          starRating
          reviewer {
            displayName
            profilePhotoUrl
          }
          createTime
          id
        }
      }
    }
  }
`

const Bewertungen = ({ data }) => {
  let ratings = data.allStrapiRating.edges
  const gmbRatings = [] //data.allGmbReview.edges

  ratings = [
    ...ratings,
    ...gmbRatings.map(({ node: n }) => ({
      node: {
        id: n.id,
        name: n.reviewer.displayName,
        profilePhotoUrl: n.reviewer.profilePhotoUrl,
        rating: {
          ONE: 1,
          TWO: 2,
          THREE: 3,
          FOUR: 4,
          FIVE: 5,
        }[n.starRating],
        comment: n.comment,
        date: n.createTime,
      },
    })),
  ]

  //order
  ratings = ratings.sort((a, b) => (a.node.date < b.node.date ? 1 : -1))

  const [ratingName, setRatingName] = useState("")
  const [ratingEmail, setRatingEmail] = useState("")
  const [ratingStars, setRatingStars] = useState(4.5)
  const [ratingComment, setRatingComment] = useState("")

  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleRatingSubmit = event => {
    event.preventDefault()

    setIsLoading(true)
    // sendConversion("contact_submit")
    axios
      .post("/.netlify/functions/send_mail", {
        ratingName,
        ratingEmail,
        ratingStars,
        ratingComment,
      })
      .then(response => {
        // console.log(response)
        setShowSuccessAlert(true)
        // clear vars
        setRatingName("")
        setRatingEmail("")
        setRatingStars(4.5)
        setRatingComment("")
      })
      .catch(error => {
        console.log(error)
        alert(
          "Leider ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut oder schreiben Sie uns eine E-Mail."
        )
      })
      .then(() => {
        setIsLoading(false)
      })
  }

  // only ratings with stars
  const fullRatings = ratings.filter(({ node: r }) => !!r.rating)

  const reviews = fullRatings.map(({ node: r }) => ({
    "@type": "Review",
    author: r.name,
    datePublished: r.date,
    description: r.comment,
    reviewRating: {
      "@type": "Rating",
      bestRating: "5",
      ratingValue: r.rating,
      worstRating: "1",
    },
  }))

  const ratingSum = fullRatings.reduce((accumulator, currentValue) => {
    return (
      (typeof accumulator === "object"
        ? accumulator.node.rating
        : accumulator) + currentValue.node.rating
    )
  })
  const avgRatings = ratingSum / fullRatings.length || 0

  const ldJson = {
    "@context": "http://schema.org/",
    "@type": "Product",
    name: "Maklerzentrale",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: avgRatings,
      reviewCount: fullRatings.length,
    },
    review: reviews,
  }

  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {
        fixed: false,
        bgColor: "white",
        color: "light",
      },
    }))
  }, [])

  dayjs.locale("de")
  dayjs.extend(relativeTime)

  return (
    <>
      <HelmetHead title="Maklerzentrale Bewertungen">
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </HelmetHead>

      <section className="pt-6 pt-md-8 pb-8 mb-md-8 bg-light border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row mb-6 mb-md-8">
                <div className="col">
                  <h1 className="fw-bold mb-0">Maklerzentrale Bewertungen</h1>
                  <span className="badge rounded-pill bg-success-soft float-end">
                    <span className="h6 text-uppercase">
                      {ratings.length} Bewertungen
                    </span>
                  </span>
                  <p className="fs-lg text-muted mb-0">
                    Lesen Sie die Erfahrungsberichte und Bewertungen der Nutzer
                    der Maklerzentrale!
                  </p>
                  <ChevronRight size={15} />
                  <u>
                    <a href="#bewertung-schreiben" onClick={smoothScroll}>
                      Bewertung schreiben
                    </a>
                  </u>
                </div>
              </div>

              <div className="card shadow-light-lg mb-5 mb-md-6">
                <div className="list-group">
                  {ratings.map(({ node: r }) => (
                    <div className="list-group-item" key={r.id}>
                      <div className="d-flex align-items-center">
                        <div className="me-auto">
                          <h4 className="fw-bold mb-0">
                            {r.profilePhotoUrl && (
                              <img
                                height="40"
                                width="40"
                                className="me-1 my-1"
                                src={r.profilePhotoUrl}
                              />
                            )}{" "}
                            {r.name}
                            {r.rating ? (
                              <Rating
                                initialRating={r.rating}
                                readonly
                                emptySymbol={
                                  <Star style={{ color: "#ffb900" }} />
                                }
                                fullSymbol={
                                  <Star
                                    style={{ color: "#ffb900" }}
                                    fill="#ffb900"
                                  />
                                }
                                className="ms-2"
                                style={{ verticalAlign: "text-bottom" }}
                              />
                            ) : null}
                          </h4>

                          <p className="fs-sm text-muted mb-0">{r.comment}</p>
                        </div>

                        <div className="text-muted ms-auto">
                          <span className="fs-sm me-4 d-none d-md-inline">
                            {dayjs().diff(r.date, "days") < 7
                              ? dayjs(r.date).fromNow()
                              : dayjs(r.date).format("DD.MM.YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className="card card-bleed shadow-light-lg mb-6"
                id="bewertung-schreiben"
              >
                <div className="card-header">
                  <h4 className="mb-0">Bewertung schreiben</h4>
                </div>
                <div className="card-body">
                  <form onSubmit={handleRatingSubmit}>
                    {showSuccessAlert ? (
                      <Alert
                        variant="success"
                        onClose={() => setShowSuccessAlert(false)}
                        dismissible
                        className="mb-5"
                      >
                        Vielen Dank für Ihre Bewertung! Wir werden Ihre
                        Bewertung vor Veröffentlichung prüfen.
                      </Alert>
                    ) : null}
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="ratingName">
                            Name
                          </label>
                          <input
                            className="form-control"
                            id="ratingName"
                            value={ratingName}
                            onChange={e => setRatingName(e.target.value)}
                            type="text"
                            placeholder="Ihr Name"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="ratingEmail">
                            E-Mail
                          </label>
                          <input
                            className="form-control"
                            id="ratingEmail"
                            value={ratingEmail}
                            onChange={e => setRatingEmail(e.target.value)}
                            type="email"
                            required
                            placeholder="Ihre E-Mail"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="ratingStars">
                            Wertung
                          </label>
                          <br />
                          <Rating
                            id="ratingStars"
                            initialRating={ratingStars}
                            fractions={2}
                            onChange={value => setRatingStars(value)}
                            emptySymbol={
                              <Star
                                style={{ color: "#ffb900" }}
                                width="30"
                                height="30"
                              />
                            }
                            fullSymbol={
                              <Star
                                style={{ color: "#ffb900" }}
                                fill="#ffb900"
                                width="30"
                                height="30"
                              />
                            }
                            style={{
                              verticalAlign: "text-bottom",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label" htmlFor="ratingComment">
                            Kommentar
                          </label>
                          <textarea
                            className="form-control"
                            id="ratingComment"
                            rows="3"
                            value={ratingComment}
                            required
                            onChange={e => setRatingComment(e.target.value)}
                          />
                        </div>

                        <div className="row align-items-center">
                          <div className="col-6 col-sm-4">
                            <button
                              className="btn btn-block btn-primary w-100"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading && (
                                <span
                                  className="spinner-grow spinner-grow-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              Senden
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Bewertungen
